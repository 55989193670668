import { Button, Snackbar } from '@mui/material'
import { useState } from 'react'
const CopyToClipboardButton = (props) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(props.text);
    }

    return (
        <>
        <Button onClick={handleClick}>Copy Link</Button>
        <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={2000}
            message="Copied to clipboard"
        />
        </>
    );
}
export default CopyToClipboardButton