import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { json, useParams } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CopyToClipboardButton from './CopyToClipboard';
import { WebSocketDemo } from './WebsocketDemo';


var ws;

export default function Room() {
  const [participants, setParticipants] = React.useState(0);
  const [events, setEvents] = React.useState([])

  var {roomName} = useParams();

  const countCallback = (new_count) => {
    setParticipants(new_count-1);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid xs={12} md={12} margin={8}>

        </Grid>
        <Grid xs={0} md={2}></Grid>
        <Grid xs={12} md={8}>
          <Card>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Room: {roomName} <CopyToClipboardButton text={window.location.toString()}></CopyToClipboardButton>
            </Typography>
            <Typography variant="h5" component="div">{participants} Other Participants Connected</Typography>

            <WebSocketDemo
            clientCountCallback={countCallback}
            room={roomName}
            />
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={0} md={2}></Grid>
      </Grid>
    </Box>
  );
}
