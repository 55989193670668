import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import uuid from 'react-uuid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Landing() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid xs={12} md={12} margin={8}>

        </Grid>
        <Grid xs={0} md={2}></Grid>
        <Grid xs={12} md={8}>
          <Item>
            <Typography variant="h4" component="h2" letterSpacing={-2}>
                Present <Typography display="inline" variant="h4" component="h2" letterSpacing={-2} color='primary'>Without</Typography>  Distractions
            </Typography>

            <Typography variant="body1">
                Stop staying <Typography display="inline" variant="body1" color="primary.dark">"next slide"</Typography> and start connecting with your audience.
            </Typography>
          </Item>
        </Grid>
        <Grid xs={0} md={2}></Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid xs={1} md={2}></Grid>
        <Grid 
            xs={10}
            md={8}
            alignItems="center"
            justifyContent="center"
        >
            
            <Link href={"Room/" + uuid()} >
                    Get Started
            </Link>
        </Grid>
        <Grid xs={1} md={2}></Grid>
      </Grid>
    </Box>
  );
}
