import * as React from 'react';
import ResponsiveAppBar from './Components/Navigation';
import Landing from './Components/Landing';
import Room from './Components/Room';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



export default function NextSlide() {
  return (
    <Router>
    <div>
    <ResponsiveAppBar/>

    <Routes>
      <Route path="/" element={<Landing/>}/>
      <Route path="/room/:roomName" element={<Room/>}/>
    </Routes>
    </div>
    </Router>
  );
}