import React, { useRef, useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Snackbar from '@mui/material/Snackbar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import NotificationSound from "./audio.mp3";
import { Notifications } from '@mui/icons-material';

export const WebSocketDemo = (props) => {
  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState('wss://api.nextsli.de/' + props.room);
  const [messageHistory, setMessageHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(false);
  const [alertsEnabled, setAlertsEnabled] = useState(false);
  const [notificationsChecked, setNotificationsChecked] = useState(false);

  const audioPlayer = useRef(null);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        var content = JSON.parse(lastMessage.data);
        if ("clients" in content) {
            props.clientCountCallback(content["clients"]);
        }
        if ("confirmed" in content) {
            if ("alert" in content["confirmed"]) {
                setMessage("Sent " + content["confirmed"]["alert"]);
                setOpen(true);
            }
            
        }
        if ("alert" in content) {
            const text = content["alert"];
            setMessage(text);
            setOpen(true);
            if (alertsEnabled) {
                const notify = new Notification(text);
                setTimeout(() => notify.close(), 2*1000);
            }
            if (soundEnabled) {
                audioPlayer.current.pause();
                audioPlayer.current.currentTime = 0;
                audioPlayer.current.play();
            }
        }
      }
      catch {
        console.log("ERROR: Unable to parse websocket payload.");
      }
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory, props]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSound = (event) => {
    console.log("sound toggle");
    setSoundEnabled(event.target.checked);

    audioPlayer.current.pause();
    audioPlayer.current.currentTime = 0;
    audioPlayer.current.play();
    audioPlayer.current.pause();
  };

  const handleNotifications = (event) => {

    if (notificationsChecked) {
        setMessage("Disabling Native Notifications");
        setOpen(true);
        setAlertsEnabled(false);        
    }
    else {
        if (Notifications.permission === "granted") {
          setNotificationsChecked(true);
          console.log("set notifications enabled");
          setAlertsEnabled(event.target.checked);
          setMessage("Enabling Native Notifications");
          setOpen(true);
          setAlertsEnabled(true)
        }
        else {
          const handle_toggle = () => {
              if (Notification.permission !== 'granted') {
                setNotificationsChecked(false);
                setMessage("Notifications Blocked by Browser");
                setOpen(true);
                setAlertsEnabled(false);
            }
            else {
                setNotificationsChecked(true);
                console.log("set notifications enabled");
                setAlertsEnabled(event.target.checked);
                setMessage("Enabling Native Notifications");
                setOpen(true);
                setAlertsEnabled(true)
            }   
          }
          try {
            Notification.requestPermission().then(handle_toggle);                                                                                                                                     
          } catch (error) {
              // Safari doesn't return a promise for requestPermissions and it                                                                                                                                       
              // throws a TypeError. It takes a callback as the first argument                                                                                                                                       
              // instead.
              if (error instanceof TypeError) {
                  Notification.requestPermission(() => {                                                                                                                                                             
                      handle_toggle();
                  });
              } else {
                  throw error;                                                                                                                                                                                       
              }                                                                                                                                                                                                      
          }   
        }

    }
    setNotificationsChecked(!notificationsChecked);
  };

  const handleClickPrevious = useCallback(() => sendMessage(
    JSON.stringify({"alert":"Previous Slide"})), []);
  
  const handleClickNext = useCallback(() => sendMessage(
    JSON.stringify({"alert":"Next Slide"})), [sendMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Interval Ran!');
      sendMessage(
        JSON.stringify({"heartbeat": 42 }));

    }, 20000);
    return () => clearInterval(interval);
  }, [sendMessage, connectionStatus, props]);

  setInterval( () => {
    // do some stuff
    if (connectionStatus === ReadyState.CONNECTING) {
      // Try again in 3 seconds
    }
    else if (connectionStatus === ReadyState.CLOSING || connectionStatus === ReadyState.CLOSED || connectionStatus === ReadyState.UNINSTANTIATED) {
      setSocketUrl('wss://api.nextsli.de/' + props.room);
      // Reconnect and try again in 5 seconds.
    }
    else if (connectionStatus === ReadyState.OPEN) {
      // Send heartbeat and repeat in 30 seconds.
      sendMessage(
        JSON.stringify({"heartbeat": Math.random() }));
    }

  }, 20000);

  return (
    <div>
      <button
        onClick={handleClickPrevious}
        disabled={readyState !== ReadyState.OPEN}
      >
        Previous Slide
      </button>
      <button
        onClick={handleClickNext}
        disabled={readyState !== ReadyState.OPEN}
      >
        Next Slide
      </button>
      <Snackbar
        open={open}
        message={message}
        autoHideDuration={2000}
        onClose={handleClose}
        />
        <FormGroup>
            <FormControlLabel control={<Switch onChange={handleSound} />} label="Enable Sound Alerts" />
            { typeof Notification === 'undefined' || 
            <FormControlLabel control={<Switch checked={notificationsChecked} onClick={handleNotifications} />} label="Enable Notifications" />
            }
        </FormGroup>
        <audio ref={audioPlayer} src={NotificationSound} />
    </div>

  );
}